import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { reset } from '../../../stores/actions/auth';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useParams()
  console.log(token)

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      dispatch(reset({password: formik.values.password, token: token.token }, navigate))
    },
  });

  console.log(formik.values.password)

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Reset
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
