import { START_LOADING, END_LOADING, FETCH_FORMS, FETCH_FORM, FETCH_FORMS_BY_USER, CREATE_FORM, UPDATE_FORM, DELETE_FORM, ADD_FORM_TO_QUEUE  } from '../../constants/types';


export const forms = (state = { isLoading: false, forms: [], all: [], queue:[] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_FORMS:
      return {
        ...state,
        forms: action.payload
      };
    case FETCH_FORMS_BY_USER:
      return { ...state, forms: action.payload };

    case "ALL_FORMS":
      return { ...state, all: action.payload };

    case FETCH_FORM:
      return { ...state, form: action.payload };

    case CREATE_FORM:
      return { ...state, forms: [...state.forms, action.payload] };
    
      case ADD_FORM_TO_QUEUE:
        return { ...state, queue: action.payload };

    case UPDATE_FORM:
      return { ...state, forms: state.forms.map((post) => (post._id === action.payload._id ? action.payload : post)) };

      // case ADD_FORM_TO_QUEUE:
      //   return { ...state, forms: state.forms.map((post) => (post._id === action.payload.id ? {...post, records: [...records, action.payload.value]} : post)) };

    case DELETE_FORM:
      return { ...state, forms: state.forms.filter((post) => post._id !== action.payload) };

    default:
      return state;
  }
};


export default forms