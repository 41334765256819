import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';


export default function CustomTooltip({ description}) {
  return (
    <div>
      <Tooltip title={description}>
      <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
