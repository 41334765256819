import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { forgot } from '../../../stores/actions/auth';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [sent, setSent] = useState(false)

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      // password: '',
      // remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      dispatch(forgot({email: formik.values.email}, setSent))
      // navigate('/dashboard', { replace: true });
    },
  });

  console.log(formik.values.email)

  // console.log(formik.values)

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if(sent) {
    return(
      <Stack>
        <text style={{color: 'green', fontSize: '20px'}}>Link sent successfully! Please check your mail</text>
      </Stack>
    )
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Send link
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
