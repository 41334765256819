import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactQuil from '../components/ReactQuill/ReactQuil'
import { getPost, updatePost } from '../stores/actions/post';
import Uploader from '../components/Uploader/Uploader';
import Progress from '../components/Progress'

const Editor = () => {

    
    const { post } = useSelector((state) => state.posts)
    console.log(post)


    const { slug } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [postData, setPostData] = useState({});



 useEffect(() => {
    dispatch(getPost(slug))
    // eslint-disable-next-line 
}, [slug])

useEffect(() => {
    if(post) {
        setPostData({
            title: post.title,
            cover: post.cover,
            content: post.content,
            userId: post.userId,
            author: post.author
        })
    }
},[post])


 const handleSubmit =() => {
    dispatch(updatePost(post._id, postData, navigate))
 }


 if(!post) {
    return (
      <div>
        <Progress />
      </div>
    )
  }

  return (
    <div>
        <Typography variant='h3' style={{textAlign: 'center'}}>
            Edit Post
        </Typography>
        <Container>
            <TextField
                fullWidth
                type="text"
                label="Post title"
                value={postData.title}
                onChange={(e) => setPostData({ ...postData, title: e.target.value })}
            />
        
            <div>

                <Uploader postData={postData} setPostData={setPostData} />
            </div>
        </Container>
        <ReactQuil  postData={postData} setPostData={setPostData} />
        
        <div style={{margin: '0px 20px', float: 'right'}}>
            <LoadingButton size="large" type="submit" variant="contained" onClick={handleSubmit}>
            Publish Post
            </LoadingButton>
        </div>
    </div>
  )
}

export default Editor