import { AUTH, LOGOUT, UPDATE_USER, START_LOADING, END_LOADING } from '../../constants/types'

const authReducer =(state = {authData: null, isLoading: false}, action)=> {
    switch (action.type) {
        
        case START_LOADING:
            return { ...state, isLoading: true };
        
        case END_LOADING:
            return { ...state, isLoading: false };

            case AUTH:
                localStorage.setItem('profile', JSON.stringify({...action?.data}))
                return {...state, authData: action?.data}
    
                case LOGOUT:
                    localStorage.removeItem('profile')
                    return {...state, authData: null}
    
                case UPDATE_USER:
                    localStorage.setItem('profile', JSON.stringify({...action?.data}))
                    // console.log(action?.data)
                    return {...state, authData: action?.data}
            
            default:
                return state
    }
}

export default authReducer