import React, { useState, useEffect } from 'react';
import parser from 'html-react-parser'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Divider } from '@mui/material';
import Page from '../components/Page';
import Navbar from '../components/Nav/NavBar';
import AuthorCard from '../components/AuthorCard';


import { getPost } from  '../stores/actions/post';
import Footer from '../components/Footer/Footer';
import Progress from '../components/Progress'

import DocContainer from '../components/DocContainer'

export default function Docs() {


  return (
    <Page title="About">
        <Navbar />
      <main>
        <Box
          sx={{
            bgcolor: '#f5f7fa',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">

            <div style={{marginTop: '80px'}}>
               <DocContainer /> 
            </div>

          </Container>
        </Box>
        
      </main>
      <Footer />
      </Page>

  );
}