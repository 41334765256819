import * as React from 'react';
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SpeciesTable from '../SpeciesTable/SpeciesTable'

/* eslint-disable */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ setOpen, open, uniqueSpecies }) {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

const lifeList = uniqueSpecies.map((species) => {
  return {
    date: species.species[`${' Date'}`],
    longitude:  species.species[`${' Longitude'}`],
    latitude:  species.species[`${' Latitude'}`],
    englishName: species.species.englishName,
    scientificName: species.species.scientificName,
    status:  species.species.status,
    family: species.species.family,
    // country: species.species.locality,
    protocol: species.species.protocol,
    observer: species.species.observer,
    french: species.species.french,
    portuguese: species.species.portuguese,
  }
})

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar elevation={6} sx={{ position: 'relative'}} style={{ backgroundColor: '#00ED64', color: 'black' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            
            </Typography>

            <CSVLink
            data={lifeList}
            filename={"lifeList.csv"}
            className="btn btn-primary"
            target="_blank"
            style={{textDecoration: 'none'}}
          >
            <Button variant='outlined' style={{color: 'black'}} endIcon={<FileDownloadIcon />} onClick={handleClose} component={'a'}>
              Export CSV
            </Button>
        </CSVLink>
          </Toolbar>
        </AppBar>
        <SpeciesTable  uniqueSpecies={uniqueSpecies} />
      </Dialog>
    </div>
  );
}
