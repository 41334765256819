// Copyright: Panshak Solomon (ipanshak@gmail.com)
// A.P. Leventis Ornithological Research Institute.
// University of Jos Biological Conservatory
// All right reserved
// ©2022 and beyond

import axios from 'axios';


// const API = axios.create({ baseURL: 'http://localhost:5000' });
const API = axios.create({ baseURL: 'https://birdplus.herokuapp.com' });


API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }

    return req
})

export const fetchForm = (id) => API.get(`/forms/${id}`);
// export const fetchAllForms = () => API.get('/all');
export const fetchFormsByUser = (searchQuery) => API.get(`/forms?searchQuery=${searchQuery.search}`);
export const createForm = (newForm) => API.post('/forms', newForm);
export const fetchForms = (page) => API.get(`/forms/all`);
export const updateForm = (id, updatedForm) => API.patch(`/forms/${id}`, updatedForm);
export const deleteForm = (id) => API.delete(`/forms/${id}`);
export const addRecord = (value, id) => API.post(`/forms/${id}/record`, value);

export const fetchPost = (id) => API.get(`/blogposts/${id}`);
export const fetchPosts = (page) => API.get(`/blogposts?page=${page}`);
export const fetchPostsByUser = (searchQuery) => API.get(`/blogposts/user?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/blogposts', newPost);
export const updatePost = (id, updatedPost) => API.patch(`/blogposts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/blogposts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const reset = (formData) => API.post('/user/reset', formData);
export const forgot = (formData) => API.post('/user/forgot', formData);


export const fetchbird = (id) => API.get(`/bird/${id}`);
export const fetchBirds = (page) => API.get(`/birds?page=${page}`);
export const fetchBirdsByUser = (searchQuery) => API.get(`/birds/user?searchQuery=${searchQuery.search}`);