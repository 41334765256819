import { START_LOADING, END_LOADING, FETCH_FORMS, FETCH_FORM, FETCH_FORMS_BY_USER, CREATE_FORM, UPDATE_FORM, DELETE_FORM, ADD_RECORD } from '../../constants/types'
import * as api from '../../api'
import { FORM_DETAIL, FORM_NAVIGATOR } from '../../constants/routeNames';
// import { v4 as uuidv4 } from 'uuid';


export const getForm = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchForm(id);

    dispatch({ type: FETCH_FORM, payload: { post: data } });
  } catch (error) {
    console.log(error);
  }
}

export const getForms = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { forms } = await api.fetchForms();

    console.log("forms", forms)
 
    dispatch({ type: "ALL_FORMS", payload: { forms } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getFormsByUser = (searchQuery) => async (dispatch) => {
  try {
    const { data } = await api.fetchFormsByUser(searchQuery);
      dispatch({ type: FETCH_FORMS_BY_USER, payload: data});

  } catch (error) {
    console.log(error);
  }
};



export const createForm = (post, navigate) => async (dispatch) => {
  try {
      //  setStep(1)
    const { data } = await api.createForm(post);

    dispatch({ type: CREATE_FORM, payload: data });
    navigate('/dashboard/data')

  } catch (error) {
    console.log(error);
  }
}




export const cloneForm = (post) => async (dispatch) => {
  
  try {
    const { data } = await api.createForm(post);

    dispatch({ type: CREATE_FORM, payload: data });

  } catch (error) {
    console.log(error);
  }
}


export const updateForm = (id, post, setStep, navigation) => async (dispatch) => {
  setStep(1)
  try {
    const { data } = await api.updateForm(id, post);

    dispatch({ type: UPDATE_FORM, payload: data });
    setStep(0)
    navigation.navigate(FORM_NAVIGATOR, { screen: FORM_DETAIL,  params: data });
  } catch (error) {
    console.log(error);
  }
};


export const updateLocalForm = (id, post, setStatus) => async () => {
  try {
   
     await api.updateForm(id, post);
     setStatus(true)
      console.log('form updated successful')
    
  } catch (error) {
    console.log(error);
    setStatus(true)
  }
};

// eslint-disable-next-line
export const addRecord = (value, id, refRBSheet, setStep) => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING })
    const { data } = await api.addRecord(value, id);
    setStep(0)
    dispatch({ type: ADD_RECORD, payload: data });
    // dispatch({ type: END_LOADING })
    return data.records;
  } catch (error) {
    console.log(error.message);
  }
};



export const deleteForm = (id) => async (dispatch) => {
  try {
    await api.deleteForm(id);

    dispatch({ type: DELETE_FORM, payload: id });
  } catch (error) {
    console.log(error);
  }
};