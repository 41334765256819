import React, { useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,

} from "@mui/material";
import { Link } from "react-router-dom";

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";


function DrawerComponent() {
    // const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const navStyle = {textDecoration: 'none', paddingRight: '16px', color: 'gray', padding: '6px' }

  return (
    <>
      <Drawer
      anchor="top"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
            sx: { width: "100%" },
          }}
      >
        <List
      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Grid container justifyContent="flex-end">
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} >
                <CloseIcon />
            </IconButton>
          </Grid>
        </ListSubheader>
      }
    >
      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        {/* <ListItemText primary="Sent mail" /> */}
        <Link to="/" style={navStyle}>
                Home
        </Link>
      </ListItemButton>

      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        {/* <ListItemText primary="Sent mail" /> */}
        <Link to="/about" style={navStyle}>
                About
        </Link>
      </ListItemButton>

      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        <Link to="/news" style={navStyle}>
                News
        </Link>
      </ListItemButton>

      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        <Link to="/docs" style={navStyle}>
                Docs
        </Link>
      </ListItemButton>

      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        {/* <ListItemText primary="Sent mail" /> */}
        <Link to="/contact" style={navStyle}>
               Contact
        </Link>
      </ListItemButton>

      <ListItemButton onClick={()=>setOpenDrawer(false)} >
        {/* <ListItemText primary="Sent mail" /> */}
        <Link to="/login" style={navStyle}>
               Login
        </Link>
      </ListItemButton>


    </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} >
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
