import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';

import { getPostsByUser, deletePost } from  '../stores/actions/post';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(214, 255, 214)',
    color: 'black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function CustomizedTables() {

    
    const dispatch = useDispatch()
    const naviage = useNavigate()
    
    const user = JSON.parse(localStorage.getItem('profile'))

    const { posts } = useSelector((state) => state.posts);
  
    console.log(posts)
  
    useEffect(() => {
        dispatch(getPostsByUser({search: user?.result?._id}));
        // eslint-disable-next-line
    }, []);
  
    const openPost =(slug) => naviage(`/dashboard/edit/${slug}`)
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell align="right">createdAt</StyledTableCell>
            <StyledTableCell align="right">Edit</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {posts.map((row) => (
            <StyledTableRow key={row._id}>

              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              
              <StyledTableCell align="left" >{moment(row.createdAt).fromNow()}</StyledTableCell>
              
              <StyledTableCell align="right">
                <IconButton aria-label="edit" onClick={() => openPost(row.slug)}>
                <BorderColorRoundedIcon />
                </IconButton>
              </StyledTableCell>

              <StyledTableCell align="right">
                <IconButton aria-label="delete" onClick={() => dispatch(deletePost(row._id))}>
                <DeleteOutlineRoundedIcon />
                </IconButton>
              </StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
