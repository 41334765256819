import React from 'react'
import { Typography, Box, Divider } from '@mui/material';
import Link from '@mui/material/Link';
import playstore from '../../assets/playstore.png'


const Footer = () => {

    function Copyright() {
        return (
          <Box >
          
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px 0px 25px 0px'}}>
            <a href="https://play.google.com/store/apps/details?id=com.birdplus" rel="noreferrer">
              <img src={playstore} style={{height: 50}} alt="get it on playstore"/>
            </a>
          </div>

          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://birdplus.org">
              BirdPlus
            </Link>{' '}
            {new Date().getFullYear()}
            {'. | Build by '}
            <Link color="inherit" href="https://panshak.com" target="_blank">
              Panshak
            </Link>
          </Typography>
          
          </Box>
        );
      }

  return (
    <div>
    <Divider />
    {/* <Box sx={{ bgcolor: '#f5f7fa', p: 7, pb: 0 }} component="footer"> */}
    <Box sx={{ bgcolor: '#ffffff', p: 7, pb: 0 }} component="footer">
        {/* <Typography variant="h6" align="center" gutterBottom>
          BirdPlus
        </Typography> */}

        <div>


          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img style={{marginBottom: '15px', marginRight: '25px'}} width='60' height="60" src="https://i.postimg.cc/j2VVtYpV/aplori-logo.png" alt="aplori.org"/>
              <img style={{marginBottom: '15px', marginRight: '25px'}} width='60' height="60" src="https://i.postimg.cc/TPTjD7Fd/download.png" alt="gbif"/>
              <img style={{marginBottom: '15px', marginRight: '25px'}} width='60' height="60" src="https://i.postimg.cc/500wPHfD/JRS-Square-Logo-Final1-300dpi.png" alt="jrs"/>
          </div>
        <Typography
          // variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          fontSize={12}
        >
          BirdPlus is made possible through funding received from The A.P. Leventis Ornithological Research Institute (APLORI),
        </Typography>
        <Typography
          // variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          fontSize={12}
        >
          University of Jos, Nigeria and The Global Biodiversity Information Facility (GBIF)
          {/* BirdPlus is funded by the A.P. Leventis Ornithological Research Institute (APLORI), University of Jos, Nigeria */}
        </Typography>
        </div>
        <div>
        <Copyright />
        </div>
      </Box>
      </div>
  )
}

export default Footer