
export const checkStatus =(status)=> {
    switch(status) {
        case "LC": 
        return "#3E6947"

        case "CR":
        return "#ff0000"

        case "EN":
        return "#ff6060"

        case "VU":
        return "#F9E814"

        case "NT":
        return "#CCE226"

        case "DD":
        return "#D1D1C6"

        default:
            return null

    }
}