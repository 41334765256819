import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileBase from 'react-file-base64';
import BraftEditor from '../components/BraftEditor'
import { createPost } from '../stores/actions/post';
import Uploader from '../components/Uploader/Uploader';

const Editor = () => {


    const user = JSON.parse(localStorage.getItem('profile'))

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [postData, setPostData] = useState({ 
        title: '', 
        content: '', 
        cover: '',
        userId: '',
        createdAt: new Date(),
        author: {name: '', avatarUrl: '', userId: ''}
 });

 console.log(postData)

 useEffect(() => {
    if(user) {
        setPostData({...postData, userId: user?.result?._id,  author: {name: user?.result?.name, avatarUrl: user?.result?.avatarUrl, userId: user?.result?._id}})
    }
 },[])


 const handleSubmit =() => {
    dispatch(createPost(postData, navigate))
 }

  return (
    <div>
        <Typography variant='h3' style={{textAlign: 'center'}}>
            New Post
        </Typography>
        <Container>
            <TextField
                fullWidth
                type="text"
                label="Post title"
                value={postData.title}
                onChange={(e) => setPostData({ ...postData, title: e.target.value })}
            />
        
            <div>

                <Uploader postData={postData} setPostData={setPostData} />

                {/* <FileBase 
                type="file" 
                style={{border: 'solid 1px #d8d8d8', padding: '15px'}}
                multiple={false} 
                onDone={({ base64 }) => setPostData({ ...postData, cover: base64 })} 
                /> */}

            </div>
        </Container>
        <BraftEditor postData={postData} setPostData={setPostData} />
        
        <div style={{margin: '0px 20px', float: 'right'}}>
            <LoadingButton size="large" type="submit" variant="contained" onClick={handleSubmit}>
            Publish Post
            </LoadingButton>
        </div>
    </div>
  )
}

export default Editor