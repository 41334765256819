import * as React from 'react';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AuthorCard({ post }) {
  return (
    <Stack direction="row" spacing={3} padding={1} alignItems="center">
      <Avatar alt={post?.author?.name} src={post?.author?.avatarUrl} />
     <Typography fontSize={14}>
        {post?.author?.name}
     </Typography>

     <Typography fontSize={14} color="gray">
        {/* {post.createdAt} */}
       { moment(post.createdAt).format("MMM Do YYYY")}
     </Typography>
    </Stack>
  );
}