import React, { useState, useEffect } from 'react';
import parser from 'html-react-parser'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Divider } from '@mui/material';
import Page from '../components/Page';
import Navbar from '../components/Nav/NavBar';
import AuthorCard from '../components/AuthorCard';

import { getPost } from  '../stores/actions/post';
import Footer from '../components/Footer/Footer';
import Progress from '../components/Progress'
import ShareButtons from '../components/ShareButtons';

export default function PageDetail() {

  const { post, isLoading } = useSelector((state) => state.posts)
  const dispatch = useDispatch()
  const { slug } = useParams()

  // console.log(post)


  useEffect(() => {
      dispatch(getPost(slug))
      // eslint-disable-next-line 
  }, [slug])



  if(!post) {
    return (
      <div>
        <Progress />
      </div>
    )
  }
  return (
    <Page title={post?.title}>
        <Navbar />
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 16,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              lineHeight={1.1}
              gutterBottom
              paddingBottom={1}
            >
              {post.title}
            </Typography>
            <Divider />
            <AuthorCard post={post}/>
            <Divider />

            <div style={{padding: '20px 0px'}}>
              <img src={post.cover} alt={post.title} width="600px" />
            </div>

            <div style={{paddingBottom: '30px'}}>
            {/* eslint-disable  */}
            {parser(post?.content)}
            </div>

            <Divider />
            <ShareButtons url={`https://birdplus.org/news/${post.slug}`} title={post.title}/>
          </Container>
        </Box>
        
      </main>
      <Footer />
      </Page>

  );
}