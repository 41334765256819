/* eslint-disable */ 
import React from "react";
// import "./styles.css";
import "braft-editor/dist/index.css";
import BraftEditor from "braft-editor";
// import { message } from "antd";

const maxFileSize = 100000; //100 kb

export default function App({ postData, setPostData}) {
  const controls = [
    "bold",
    "italic",
    "underline",
    "separator",
    "link",
    "separator",
    "media"
  ];


  // let editorState = BraftEditor.createEditorState(
  //   `<p>Hello World</p>`,
  //   // { unitImportFn }
  // );


  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const customUpload = props => {
    const { file, success, error } = props;
    toBase64(file)
      .then(res => {
        success({ url: res });
      })
      .catch(err => {
       alert(" File upload failed");
        error(err);
      });
  };

  const validateFn = file => {
    let fileSizeError = "File Should be less than 100 kb";

    if (file.size > maxFileSize) {
      alert(fileSizeError);
      return false;
    }
    return true;
  };

  return (
      <div style={{width: '95%', 
      padding: '20px', 
      border: 'solid 1px #d8d8d8', 
      margin: '10px 20px', 
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
      }}>
        <BraftEditor
          language="en"
          onChange={(e) => setPostData({...postData, content: e.toHTML()})}
          value={postData.content}
          media={{ uploadFn: customUpload, validateFn: validateFn }}
          contentStyle={{
            boxShadow: "inset 0 1px 3px rgba(0,0,0,.1)"
          }}
        />
      </div>
  );
}
