import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import { getPosts } from  '../stores/actions/post';
import Navbar from '../components/Nav/NavBar';
import Footer from '../components/Footer/Footer';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function Blog() {

  const naviage = useNavigate()
  const user = JSON.parse(localStorage.getItem('profile'))
  const dispatch = useDispatch()
  const { posts, numberOfPages } = useSelector((state) => state.posts);

  console.log(posts)


  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);
 

  useEffect(() => {
      dispatch(getPosts(pageNumber));
      // eslint-disable-next-line
  }, [pageNumber]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };

  const openPost =(slug) => naviage(`/news/${slug}`)

  if(posts === undefined) {
    return <div>Loading...</div>
  }

  return (
    <Page title="Dashboard: Blog">
      <Navbar />
      <Container style={{marginTop: '90px', marginBottom: '30px'}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
         
         {user && (
           <Button variant="contained" component={RouterLink} to="/dashboard/write" startIcon={<Iconify icon="eva:plus-fill" />}>
           New Post
         </Button>
         )}

        </Stack>
{/* 
        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={posts} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}

        <Grid container spacing={3}>
          {posts.map((post, index) => (
            <BlogPostCard key={post._id} post={post} index={index} openPost={openPost} />
          ))}
        </Grid>
      </Container>

      <Footer />
    </Page>
  );
}
