import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

// import { createTheme, ThemeProvider } from '@mui/material/styles';;
import { createTheme } from '@mui/material/styles';
import { Divider,  useMediaQuery } from '@mui/material';
import Navbar from '../components/Nav/NavBar';
import Latest from '../components/Latest'
import Page from '../components/Page';

import playstore from '../assets/playstore.png'
import cliffchat from '../assets/CliffChat.png'
import geolocation from '../assets/icons/geolocation.png'
import create from '../assets/icons/create.png'
import habitat from '../assets/icons/habitat.png'
import mophometric from '../assets/icons/mophometric.png'
import language from '../assets/icons/language.png'
import csv from '../assets/icons/csv.png'
import bird from '../assets/icons/bird.png'
import field from '../assets/icons/field.png'

import Footer from '../components/Footer/Footer';


import { getPosts } from  '../stores/actions/post';


// const theme = createTheme();

export default function Home() {

  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const dispatch = useDispatch()
  const { posts, numberOfPages } = useSelector((state) => state.posts);
  // const POSTS = posts
  // console.log(posts)


  const [pageNumber, setPageNumber] = useState()
  const pages = new Array(numberOfPages).fill(0).map((v, i) => i);
 

  useEffect(() => {
      dispatch(getPosts(pageNumber));
      // eslint-disable-next-line
  }, [pageNumber]);

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  };


  const icons = [
    {
      title: 'Create Dataform(s)', 
      descrtiption: 'Create your dataform with any number (or types) of variables you want to collect', 
      link: create
    },

    // {
    //   title: 'Collect habitat variables', 
    //   descrtiption: 'Create form fields with all your habitat variables and collect them with ease', 
    //   link: habitat
    // },
    // {
    //   title: 'Collect Mophometric data', 
    //   descrtiption: 'Create form fields with all your mophometric variables and collect them with ease', 
    //   link: mophometric
    // },

    {
      title: 'Record your sightings', 
      descrtiption: 'Record your observations and collect other variables base on your research need', 
      link: bird
    },

    // {
    //   title: 'Collect point coordinates', 
    //   descrtiption: 'BirdPlus automatically record point coordinate and timestamp of each observation', 
    //   link: geolocation
    // },

    {
      title: 'Export your data as CSV', 
      descrtiption: 'Export your data as a csv file and start your data analysis', 
      link: csv
    },

    // {
    //   title: 'Support multiple protocols', 
    //   descrtiption: 'Line transect, point count, point transect, territory mapping, ringing etc', 
    //   link: field
    // },

    {
      title: 'Support multiple languages', 
      descrtiption: 'BirdPlus is multilingual and can be used in 3 languages:- English, French and Portuguese', 
      link: language
    }
  ]


  const sponsors = [
    {
      title: 'APLORI', 
      link: 'https://i.postimg.cc/j2VVtYpV/aplori-logo.png',
      website: 'https://aplori.org'
    },
    {
      title: '', 
      link: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/768px-Solid_white.svg.png',
      website: 'https://aplori.org'
    },
    {
      title: '', 
      link: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/768px-Solid_white.svg.png',
      website: 'https://aplori.org'
    },
    {
      title: '', 
      link: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/768px-Solid_white.svg.png',
      website: 'https://aplori.org'
    },
  ]


  return (
    <Page title="BirdPlus - Line transect | Point count | Bird Ringing | Territory mapping">
      <Navbar />
      <main style={{backgroundColor: 'white'}}>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 18,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
            style={{fontFamily: 'Roboto', fontWeight: '300'}}
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Collect bird data using standard protocols
            </Typography>
            <Typography style={{fontSize: '14px', textAlign: 'center'}} variant="body" align="center" color="text.secondary" paragraph>
            The BirdPlus mobile app lets you collect bird data and any other 
              variable(s) using standard protocols (line transect, point count, point transect, territory mapping, ringing, etc).
            </Typography>

            <Typography style={{fontSize: '14px', textAlign: 'center'}} variant="body" align="center" color="text.secondary" paragraph>
                Available on Playstore
            </Typography>

            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
                {/* <LoadingButton to="/login" component={RouterLink} size="large" type="submit" variant="contained">
                Login to your account
                </LoadingButton> */}
                <Box elevation={6}>
                {/* <a href="https://panshak.com" target="_blank" rel="noreferrer"> */}
                <a href="https://play.google.com/store/apps/details?id=com.birdplus" rel="noreferrer">
                  <img src={playstore} style={{height: 50}} alt="get it on playstore"/>
                </a>

                </Box>
              {/* <Button variant="outlined">Download on Playstore</Button> */}
            </Stack>
          </Container>
        </Box>

        {!isMobile && (
          <Box style={{marginTop: '-250px'}}>
            <img src={cliffchat} alt="bird" width={300} />
          </Box>
        )}
        <Divider />
        
        <Container sx={{ py: 8, backgroundColor: "#F5F7FA" }} maxWidth="100%">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {icons.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: '100px',
                      margin: '0px auto',
                      paddingTop: '20px'
                    }}
                    image={card.link}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1, pl: 5, pr: 5, pb: 10 }}>
                    <Typography gutterBottom variant="h6" component="h2" style={{fontSize: '14px', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                     {card.descrtiption}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>


        <div>
        <Typography
            style={{fontFamily: 'Roboto', fontWeight: '300', padding: '30px'}}
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
            >
           News/Updates
          </Typography>

          <div style={{paddingBottom: 70}}>
            <Latest posts={posts} />
          </div>
        </div>


        {/* SPONSORS */}

        
        {/* <Container sx={{ py: 5, backgroundColor: "#F5F7FA", mt: 8 }} maxWidth="100%">
        <Typography
            style={{fontFamily: 'Roboto', fontWeight: '300', padding: '30px'}}
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
            >
            Sponsors
          </Typography>
          
          <Grid container spacing={2}>
            {sponsors.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: '100px',
                      margin: '0px auto',
                      paddingTop: '20px'
                    }}
                    image={card.link}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1, pl: 5, pr: 5, pb: 10 }}>
                    <Typography gutterBottom variant="h6" component="h2" style={{fontSize: '14px', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container> */}

      </main>
      

    <Footer />

    </Page>
  )
}