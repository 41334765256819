import * as React from 'react';
import moment from 'moment'
// import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { checkStatus } from './checkStatus';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e8e8e8',
    color: 'black',
    padding: 13
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
   
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props) {
  const { row, index } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="left">{index + 1}</StyledTableCell>
        <StyledTableCell component="th" scope="row">
        {row.species.englishName}
        </StyledTableCell>
        <StyledTableCell align="left">{row.species.scientificName}</StyledTableCell>
        <StyledTableCell align="left">{row.species[`${' Latitude'}`]}</StyledTableCell>
        <StyledTableCell align="left">{row.species[`${' Longitude'}`]}</StyledTableCell>
        <StyledTableCell align="left">{moment(row.species[`${' Date'}`]).format("LL")}</StyledTableCell>
        <StyledTableCell align="left">
          <Avatar sx={{color: (row.species.status === ('VU' || 'DD' || 'NT')) ? 'black' : 'white', fontSize: 10, width: 24, height: 24, bgcolor: checkStatus(row.species.status) }}>{row.species.status}</Avatar>
          </StyledTableCell>
        {/* <StyledTableCell align="left">{row?.species?.locality}</StyledTableCell> */}
        <StyledTableCell align="left">{row?.species?.observer}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ backgroundColor: '#FFF7CD' }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="observations">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>English Name</StyledTableCell>
                    <StyledTableCell align="left">Scientific Name</StyledTableCell>
                    <StyledTableCell align="left">Latitude</StyledTableCell>
                    <StyledTableCell align="left">Longitude</StyledTableCell>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    {/* <StyledTableCell align="left">Country</StyledTableCell> */}
                    <StyledTableCell align="left">Observer</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row?.occurrences?.map((species, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                      {species.englishName}
                      </StyledTableCell>
                      <StyledTableCell align="left">{species.scientificName}</StyledTableCell>
                      <StyledTableCell align="left">{species[`${' Latitude'}`]}</StyledTableCell>
                      <StyledTableCell align="left">{species[`${' Longitude'}`]}</StyledTableCell>
                      <StyledTableCell align="left">{moment(species[`${' Date'}`]).format("LL")}</StyledTableCell>
                      <StyledTableCell align="left">
                        <Avatar sx={{color: (species.status === ('VU' || 'DD' || 'NT')) ? 'black' : 'white', fontSize: 10, width: 24, height: 24, bgcolor: checkStatus(species.status)}}>{species.status}</Avatar>
                        </StyledTableCell>
                      {/* <StyledTableCell align="left">{species?.locality}</StyledTableCell> */}
                      <StyledTableCell align="left">{species?.observer}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };


export default function CollapsibleTable({ uniqueSpecies }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" stickyHeader aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell>S/N</StyledTableCell>
            <StyledTableCell>English Name</StyledTableCell>
            <StyledTableCell align="left">Scientific Name</StyledTableCell>
            <StyledTableCell align="left">Latitude</StyledTableCell>
            <StyledTableCell align="left">Longitude</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            {/* <StyledTableCell align="left">Country</StyledTableCell> */}
            <StyledTableCell align="left">Observer</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {uniqueSpecies.map((row, index) => (
            <Row key={index} row={row} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
