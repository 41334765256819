import { FETCH_ALL_BIRDS, FETCH_BIRDS_BY_USER, FETCH_BIRD } from '../../constants/types';

export const birds = (state = { isLoading: true, birds: [], summary: {} }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL_BIRDS:
      return {
        ...state,
        birds: action.payload.data,
        summary: action.payload.summary,
       
      };
    case FETCH_BIRDS_BY_USER:
      return { ...state, birds: action.payload.data, summary: action.payload.summary };
    case FETCH_BIRD:
      return { ...state, bird: action.payload.post };

    default:
      return state;
  }
};