import React from "react";
import {
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink, Link } from 'react-router-dom';

import DrawerComponent from "./Drawer";
import Logo from '../Logo';



function Navbar() {

const user = JSON.parse(localStorage.getItem('profile'))
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const navStyle = {textDecoration: 'none', paddingRight: '20px', paddingLeft: '10px', color: '#4f4f4f', fontSize: '14px'}

  return (
    <AppBar position="fixed" elevation={6} style={{
            backgroundColor: '#00ED64', 
            borderBottom: '1px solid #d6d6d6', 
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'}}>
        <Box style={{paddingTop: '12px', paddingLeft: '10px'}}>
            <Logo />
        </Box>
        <Toolbar style={{minHeight: 30}} >
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div >

  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Link to="/" style={navStyle}>
        Home
    </Link>

    <Link to="/about" style={navStyle}>
        About
    </Link>
    <Link to="/contact" style={navStyle}>
        Contact
    </Link>

    <Link to="/news" style={navStyle}>
      News
    </Link>
    
    <Link to="/docs" style={navStyle}>
      Docs
    </Link>

    <Toolbar style={{minHeight: 55}} >
     {user ? (
        <LoadingButton style={{backgroundColor: 'orange', color: '#494949'}} to="/dashboard/app" component={RouterLink} size="medium" type="submit" variant="contained">
        Dashboard
      </LoadingButton>

     ): (
      <LoadingButton  style={{backgroundColor: '#187044', color: '#ffffff', marginRight: '-15px'}} to="/login" component={RouterLink} size="large" type="submit" variant="contained">
        Login
      </LoadingButton>
     )
     }
    </Toolbar>


    </div>
  </div>
      )}
    </Toolbar>
  </AppBar>
  );
}
export default Navbar;
