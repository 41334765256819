import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'underscore'
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button
} from '@mui/material';
// components
import { getFormsByUser } from '../stores/actions/form';
import Page from '../components/Page';
import CustomTooltip from '../components/CustomTooltip';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// mock
// import formList from '../_mock/user';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'observer', label: 'Observer', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'records', label: 'Records', alignRight: false },
  { id: 'species', label: 'Species', alignRight: false },
  { id: 'protocol', label: 'Protocol', alignRight: false },
  { id: 'createdAt', label: 'Date Created', alignRight: false },
  { id: 'description', label: 'Desc', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.observer.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {


//--------------------------------------------------------
const dispatch = useDispatch()
const { forms } = useSelector((state) => state.forms)
const user = JSON.parse(localStorage.getItem('profile'))

// const speciesTable = forms.map((form) => 
// form?.records?.map((record) => 
// record.species.map((specie) => ({...record.records, ...specie } ))))


/* eslint-disable */
const formList = forms.map((form) => {
  return {
    isImported: form?.isImported ? form?.isImported : false,
    id: form._id,
    title: form.title,
    isSpeciesSpecific: form?.isSpeciesSpecific,
    description: form.description,
    protocol: form?.protocol,
    isPublic: form.isPublic,
    observer: form.observer,
    createdAt: form.createdAt,
    records: (form?.records?.map((record) => {
      var result = record?.variables.map((e) => {
        return {[e.name]: (e.val).replace(/(\r\n|\n|\r)/gm, "- ")}
        });
          return record.species.map((specie) => {
          const allVariables = Object.assign({}, ...result)
            return {...record.timeLocation, ...specie, ...allVariables, protocol: form.protocol, observer: form.observer, title: form.title }
          })
      }).flat()),

      aggregated: (form?.records?.map((record) => {
        var result = record?.variables.map((e) => {
          return {[e.name]: (e.val).replace(/(\r\n|\n|\r)/gm, "- ")}
          });
            return (record?.aggregated ? record?.aggregated: []).map((specie) => {
            const allVariables = Object.assign({}, ...result)
              return {...record.timeLocation, ...specie, ...allVariables, protocol: form.protocol, observer: form.observer, title: form.title }
            })
        }).flat()),
    species: _.uniq((form.records.map((record) => record.species)).flat().filter((specie) => specie.scientificName !== 'none'), form?.isImported ? 'commonName' : 'scientificName'),
    avatarUrl: "/static/mock-images/avatars/avatar_default.jpg"
  }
})


// console.log(formList)



useEffect(() => {
  dispatch(getFormsByUser(
    { search: user?.result?.isOrganization ? 
      user?.result?.organizationCode 
      : 
      user?.result?._id 
    }
    ))
},[])



//--------------------------------------------------------



  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  // console.log(selected)

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(6);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formList.length) : 0;

  const filteredUsers = applySortFilter(formList, getComparator(order, orderBy), filterName);

  const isUserNotFound = formList.length === 0;


//---------------------------------------------------------------------------------

  const selectedDataSheets = filteredUsers?.filter(item => selected.includes(item.id))
  const selectedRecords = (selectedDataSheets?.map((record) => record.records)).flat()

  
  // ------------------------GENERATE CSV-----------------------------------------------
  
  // console.log(selectedRecords)


//------------------------------------------------------------------------------------

  return (
    <Page title="Data">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Data
          </Typography>
          {/* <Button variant="contained" component={RouterLink} to="/dashboard/importer" startIcon={<Iconify icon="eva:plus-fill" />}>
            Import Data
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar data={selectedRecords} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={formList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, isSpeciesSpecific, observer, records, species, protocol, avatarUrl, createdAt, description, aggregated } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;
                    // console.log(isItemSelected)

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={title} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {observer}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{records?.length}</TableCell>
                        <TableCell align="left">{species?.length}</TableCell>
                        <TableCell align="left">
                          {protocol && (
                            <Label variant="ghost" color={'success'}>
                            {sentenceCase(protocol)}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="left"> {moment(createdAt).fromNow()}</TableCell>

                        <TableCell align="left">
                          {description && (
                          <CustomTooltip description={description}/>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <UserMoreMenu records={records} id={id} species={species} aggregated={aggregated} isSpeciesSpecific={isSpeciesSpecific} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={formList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
