
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING" 
export const FETCH_FORMS = "FETCH_FORMS"
export const FETCH_FORM = "FETCH_FORM" 
export const FETCH_FORMS_BY_USER = "FETCH_FORMS_BY_USER"
export const CREATE_FORM = "CREATE_FORM"
export const UPDATE_FORM = "UPDATE_FORM"
export const DELETE_FORM = "DELETE_FORM"
export const ADD_RECORD = "ADD_RECORD"

export const FETCH_ENTRIES = "FETCH_ENTRIES"
export const FETCH_ENTRY = "FETCH_ENTRY" 
export const FETCH_ENTRIES_BY_FORM = "FETCH_ENTRIES_BY_FORM"
export const CREATE_ENTRY = "CREATE_ENTRY"
export const UPDATE_ENTRY = "UPDATE_ENTRY"
export const DELETE_ENTRY = "DELETE_ENTRY"
export const ADD_FORM_TO_QUEUE = "ADD_FORM_TO_QUEUE"

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"


export const FETCH_ALL = "FETCH_ALL"
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH"
export const FETCH_POST = "ETCH_POST"
export const CREATE = "CREATE"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const LIKE = "LIKE"
export const COMMENT = "COMMENT"


export const FETCH_ALL_BIRDS = "FETCH_ALL_BIRDS"
export const FETCH_BIRDS_BY_USER = "FETCH_BIRDS_BY_USER"
export const FETCH_BIRD = "FETCH_BIRD"

