import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DraftsIcon from '@mui/icons-material/Drafts';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Navbar from '../components/Nav/NavBar';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://birdplus.org/">
        BirdPlus
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function SignInSide() {

  return (
    <>
    <Navbar />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://res.cloudinary.com/wabis/image/upload/v1656493279/birdplus/B8_AFRICAN_STONECHAT2-cleaned_ion1lk.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <DraftsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Send Feedback
            </Typography>
            <Typography component="body" variant="body2" style={{textAlign: 'center', padding: '50px'}}>
              We welcome feedback, suggestions and any other inquiry. 
              Kindly click the button below to send us a message
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              
            <Button style={{padding: '15px'}} component={'a'} href="mailto:hello@birdplus.org" variant="contained" endIcon={<SendIcon />} fullWidth>
                Send Message
            </Button>
              
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      </>
  )
}