import * as api from '../../api'
import { AUTH } from '../../constants/types'

export const signin =(formData, navigate ) => async(dispatch) => {

    try {
        const { data } = await api.signIn(formData)
        dispatch({ type: AUTH, data})
        navigate('/dashboard/app', { replace: true });

    } catch (error) {
        console.log(error)
    }
}

export const signup =(formData, navigate) => async(dispatch) => {
    try {
        const { data } = await api.signUp(formData)
        dispatch({ type: AUTH, data})
        navigate('/dashboard/app', { replace: true });
    } catch (error) {
        console.log(error)
        
    }
}


export const forgot = (formData, setSent) => async() => {
    try {
        await api.forgot(formData)
        setSent(true)
    } catch (error) {
        console.log(error)
    }
}

export const reset =(formData,navigate) => async(dispatch) => {

    try {
        // await api.reset(formData)
        const { data } = await api.reset(formData)
        dispatch({ type: AUTH, data})
        navigate('/dashboard/app', { replace: true });

    } catch (error) {
        console.log(error)
    }
}