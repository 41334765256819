import React, { useState} from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { CSVLink } from "react-csv";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#c9ffdf',
    marginLeft: 0,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ open, ebirdExport, handleClose, setEbirdExport, exportToEbird }) {

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Export to eBird
        </BootstrapDialogTitle>
        <DialogContent dividers>

        <Box fullWidth>
            <TextField
                autoComplete="location"
                type="text"
                label="Location"
                style={{padding: 8}}
                onChange={(e) => setEbirdExport({...ebirdExport, location: e.target.value})}
                value={ebirdExport.location}
                
              />

            <TextField
                autoComplete="country"
                type="text"
                label="Country"
                style={{padding: 8}}
                onChange={(e) => setEbirdExport({...ebirdExport, country: e.target.value})}
                value={ebirdExport.country}
                
            />

            <TextField
                autoComplete="province"
                type="text"
                label="State/Province"
                style={{padding: 8}}
                onChange={(e) => setEbirdExport({...ebirdExport, province: e.target.value})}
                value={ebirdExport.province}
            />

            <TextField
                autoComplete="distance"
                type="text"
                label="Effort distance in miles"
                style={{padding: 8}}
                onChange={(e) => setEbirdExport({...ebirdExport, distance: e.target.value})} 
                value={ebirdExport.distance}
            />
        </Box>
         {/* </div> */}
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button> */}
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>

      <CSVLink
        data={exportToEbird}
        filename={"datasheet.csv"}
        className="btn btn-primary"
        target="_blank"
        style={{textDecoration: 'none', paddingRight: '30px'}}
      >
          <Button autoFocus onClick={handleClose}>
              Export
          </Button>
      </CSVLink>

        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
