import { combineReducers } from 'redux'
import auth from './auth'
import posts from './posts'
import {forms} from './form'
import { birds } from './birds'


const reducers = combineReducers({
  auth,
  forms,
  posts,
  birds,
})

export default reducers
