import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//     linkTo: '#',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//     linkTo: '#',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {

  const dispatch =  useDispatch()
  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem('profile'))

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false)
  };


  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    navigate('/')
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.result?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {user?.result?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {user?.result?.roles?.includes('supermod') && (
          <Stack sx={{ p: 1 }}>
          <MenuItem to='/dashboard/allforms' component={RouterLink}>
            All Forms
          </MenuItem>
      </Stack>
        )}

        <Stack sx={{ p: 1 }}>
            <MenuItem to='/news' component={RouterLink}>
              News
            </MenuItem>
        </Stack>
        <Stack sx={{ p: 1 }}>
            <MenuItem to='/' component={RouterLink} onClick={logout}>
              Logout
            </MenuItem>
        </Stack>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Logout
        </MenuItem> */}
      </MenuPopover>
    </>
  );
}
