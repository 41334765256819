/* eslint-disable */
import React, { useEffect, useState } from 'react'
import customStyles from './dash.module.css'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'underscore'
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button } from '@mui/material';
// components
import Page from '../components/Page';
import SelectForm from '../components/SelectForm';
import Home from './Home'
import Progress from '../components/Progress'
// import Iconify from '../components/Iconify';
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';


import { getFormsByUser} from '../stores/actions/form'
import FullScreenDialog from '../components/FullScreenDialog/FullScreenDialog';

// ----------------------------------------------------------------------




export default function DashboardApp() {


  const user = JSON.parse(localStorage.getItem('profile'))

  
const [list, setList] = useState([])




  const navigate = useNavigate()
  const theme = useTheme();
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const { forms } = useSelector((state) => state.forms)
  const [selectedForms, setSelectedForms] = React.useState([]);

  const activeList = selectedForms.length > 0 ? selectedForms : forms


  const formList = activeList.map((form) => {
    return {
      id: form._id,
      title: form.title,
      protocol: form.protocol,
      observer: form.observer,
      records: (form?.records?.map((record) => {
        var result = record?.variables.map((e) => {
          return {[e.name]: (e.val).replace(/(\r\n|\n|\r)/gm, "- ")}
       });
        return record.species.map((specie) => {
        const allVariables = Object.assign({}, ...result)
          return {...record.timeLocation, ...specie, ...allVariables, protocol: form.protocol, observer: form.observer }
        })
    }).flat()),
      // records: (form.records.map((record) => record.species.map((specie) => 
      //   ({...record.records, ...specie, protocol: form.protocol, observer: form.observer})
      //   )).flat()
      //   ),
      species: _.uniq((form.records.map((record) => record.species)).flat().filter((specie) => specie.scientificName !== 'none'), 'scientificName'),
      avatarUrl: "/static/mock-images/avatars/avatar_default.jpg"
    }
  })

  // console.log(formList)


  const totalObservers = _.uniq(formList, 'observer')
  const totalRecords = (formList.map((list) => list.records)).flat()


  const totalSpecies  = Object.values(totalRecords.reduce((acc, x) => {
    acc[x.speciesId] = [...(acc[x.speciesId] || []), x ];
    return acc;
  }, {}));

  // console.log(totalSpecies)
  
  const uniqueSpecies = totalSpecies.map((unique) => {
    return {species: unique[0], occurrences: unique}
  })
    

useEffect(() => {
  dispatch(getFormsByUser(
    { search: user?.result?.isOrganization ? 
      user?.result?.organizationCode 
      : 
      user?.result?._id 
    }
    ))
},[])




  //AGGREGATE ABUNDANCE RECORDS  BY MONTH
  let Jan = 0
  let Feb = 0
  let March = 0
  let April = 0
  let May = 0
  let June = 0
  let July = 0
  let August = 0
  let Sept = 0
  let Oct = 0
  let Nov = 0
  let Dec = 0
  
  
     totalRecords.map((record) => {
        const d = new Date(record.date)
      const month = d.getMonth() + 1
      if(month === 1) {
        Jan += record.count
      }
      if(month === 2) {
        Feb += record.count
      }
      if(month === 3) {
        March += record.count
      }
      if(month === 4) {
        April += record.count
      }
      if(month === 5) {
        May += record.count
      }
      if(month === 6) {
        June += record.count
      }
      if(month === 7) {
        July += record.count
      }
      if(month === 8) {
        August += record.count
      }
      if(month === 9) {
        Sept += record.count
      }
      if(month === 10) {
        Oct += record.count
      }
      if(month === 11) {
        Nov += record.count
      }
      if(month === 12) {
        Dec += record.count
      }
  
      })
  
console.log(totalRecords)
//AGGREGATE RICHNESS RECORDS  
let rJan = []
let rFeb = []
let rMarch = []
let rApril = []
let rMay = []
let rJune = []
let rJuly = []
let rAugust = []
let rSept = []
let rOct = []
let rNov = []
let rDec= []


totalRecords.map((record) => {
  const d = new Date(record.date)
const month = d.getMonth() + 1
if(month === 1) {
  rJan.push(record)
}
if(month === 2) {
  rFeb.push(record)
}
if(month === 3) {
  rMarch.push(record)
}
if(month === 4) {
  rApril.push(record)
}
if(month === 5) {
  rMay.push(record)
}
if(month === 6) {
  rJune.push(record)
}
if(month === 7) {
  rJuly.push(record)
}
if(month === 8) {
  rAugust.push(record)
}
if(month === 9) {
  rSept.push(record)
}
if(month === 10) {
  rOct.push(record)
}
if(month === 11) {
  rNov.push(record)
}
if(month === 12) {
  rDec.push(record)
}
})

// console.log(_.unique(rMay, 'scientificName'))
// console.log(rJune)
//NOW GET UNIQUE SPECIES FOR EACH MONTH
const uniqueJan = _.unique(rJan, 'scientificName')
const uniqueFeb = _.unique(rFeb, 'scientificName')
const uniqueMarch = _.unique(rMarch, 'scientificName')
const uniqueApril = _.unique(rApril, 'scientificName')
const uniqueMay = _.unique(rMay, 'scientificName')
const uniqueJune = _.unique(rJune, 'scientificName')
const uniqueJuly = _.unique(rJuly, 'scientificName')
const uniqueAugust = _.unique(rAugust, 'scientificName')
const uniqueSept = _.unique(rSept, 'scientificName')
const uniqueOct = _.unique(rOct, 'scientificName')
const uniqueNov = _.unique(rNov, 'scientificName')
const uniqueDec = _.unique(rDec, 'scientificName')



const lineTransect = totalRecords.filter((record) => record.protocol === 'lineTransect')
const pointTransect = totalRecords.filter((record) => record.protocol === 'pointTransect')
const territoryMapping = totalRecords.filter((record) => record.protocol === 'territoryMapping')
const pointCount = totalRecords.filter((record) => record.protocol === 'pointCount')
const ringing = totalRecords.filter((record) => record.protocol === 'ringing')
const others = totalRecords.filter((record) => record.protocol === 'others')


const [open, setOpen] = React.useState(false);
const handleClickOpen = () => {
  setOpen(true);
};

const openRecords =() => {
  navigate('/dashboard/forms')
}


// if(!forms.length === 0) {
//  return(
//   <Page title="Dashboard">
//     <Progress />
//     </Page>
//  )
// }

  return (
    <>
   {forms.length === 0 ? 
   ( <Progress />): 
  (
    <>
    <FullScreenDialog handleClickOpen={handleClickOpen} setOpen={setOpen} open={open} uniqueSpecies={uniqueSpecies} />
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back.
        </Typography>

        <SelectForm forms={forms} selectedForms={selectedForms} setSelectedForms={setSelectedForms} user={user} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} onClick={openRecords}>
            <AppWidgetSummary title="Records" total={totalRecords.length} icon={'ant-design:pie-chart-filled'} />
          </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className={customStyles.statCard} onClick={handleClickOpen} >
              <AppWidgetSummary title="Species" total={totalSpecies.length} color="info" icon={'ant-design:yuque-filled'} />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} onClick={openRecords} >
            <AppWidgetSummary title="Datasheets" total={formList.length} color="warning" icon={'ant-design:fund-filled'} />
          </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} >
            <AppWidgetSummary title={totalObservers.length > 1 ? "Observers" : "Observer"} total={totalObservers.length} color="error" icon={'ant-design:contacts-filled'} />
          </div>
          </Grid>

          <Grid item xs={12} md={6} lg={selectedForms.length > 0 ? 12 : 8}>
            <AppWebsiteVisits
              title="Your observations in the current year"
              subheader=""
              chartLabels={[
                'Jan',
                'Feb',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
              ]}
              chartData={[
                // {
                //   name: 'Richness',
                //   type: 'column',
                //   fill: 'solid',
                //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                // },
                {
                  name: 'Individuals',
                  type: 'area',
                  fill: 'gradient',
                  data: [Jan, Feb, March, April, May, June, July, August, Sept, Oct, Nov, Dec],
                },
                {
                  name: 'Species',
                  type: 'line',
                  fill: 'solid',
                  data: [
                    uniqueJan.length,
                    uniqueFeb.length,
                    uniqueMarch.length,
                    uniqueApril.length,
                    uniqueMay.length,
                    uniqueJune.length,
                    uniqueJuly.length,
                    uniqueAugust.length,
                    uniqueSept.length,
                    uniqueOct.length,
                    uniqueNov.length,
                    uniqueDec.length
                  ],
                },
              ]}
            />
          </Grid>

          {
            selectedForms.length === 0 && (
              <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Records by protocol"
              chartData={[
                { label: 'Line Transect', value: lineTransect.length },
                { label: 'Territory Mapping', value: territoryMapping.length },
                { label: 'Point Transect', value: pointTransect.length },
                { label: 'Point Count', value: pointCount.length },
                { label: 'Ringing', value: ringing.length },
                { label: 'Others', value: others.length },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>
            )
          }

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={12}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          
        </Grid>
      </Container>
    </Page>
    </>
  )}
    </>
  );
}
