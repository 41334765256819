 /* eslint-disable */ 
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { Button, Box, TextField, Autocomplete, useMediaQuery, createTheme } from "@mui/material";
import ReactFlexyTable from 'react-flexy-table'
import { createForm } from "../stores/actions/form";
import Iconify from '../components/Iconify';
import Page from '../components/Page';
// import SnackBar from '../components/SnackBar'
import 'react-flexy-table/dist/index.css'



export default function Importer() {

  const [snack, setSnack] = useState(false)
  const message = 'This section must all be completed'
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [csvData, setCsvData] = useState([{}])
  const [headers, setHeaders] = useState([])
  const [formData, setFormData] = useState({
    title: '',
    observer: '',
    observerId: '',
    records: [],
    options: [],
    protocol: '',
    isImported: true,
    id: uuidv4(),
    createdAt:  new Date(),
  })


  const protocols = [
    {label: 'Line Transect', value: 'lineTransect'},
    {label: 'Point Count', value: 'pointCount'},
    {label: 'Point Transect', value: 'pointTransect'},
    {label: 'Belt Transect', value: 'beltTransect'},
    {label: 'Bird Ringing', value: 'ringing'},
    {label: 'Presence/Absence', value: 'presenceAbsence'},
    {label: 'Territory Mapping', value: 'mapping'},
    {label: 'Others', value: 'others'},
  ]
 
  
  const [species, setSpecies] = useState('')
  const [number, setNumber] = useState()

  // rename keys to adjust to standard

  const changeKey = csvData.map(({ 
    [`${species}`]: commonName, 
    [`${number}`]: count, ...rest }) => ({ commonName, count, ...rest }));

  const formatted = changeKey.map((data) => {
    const species = [{name: data.commonName, count: data.count}]
    delete data.commonName
    delete data.count
    const variables = [data]
   return {timeLocation: {}, species, variables }
  })



  useEffect(() => {
    if(csvData !== null || csvData !== undefined) {
      const titles = Object?.keys(csvData[0])?.map((obj) => {
        return {label: obj, value: obj}
      })
      setHeaders(titles)
    }

  },[csvData])

  const user = JSON.parse(localStorage.getItem('profile'))

  useEffect(() => {
    setFormData({...formData, observer: user?.result?.name, observerId: user?.result?._id, records: formatted })
  },[species, number])


  const handleSubmit =() => {
    if((formData.title.length === 0) || 
        (formData.protocol.length === 0) || 
        (species.length === 0) || 
        (number.length === 0)
    ) {
      setSnack(true)
    }
    else {
      dispatch(createForm(formData, navigate))
    }
  }

  return (
    <Page title="Import">
      {/* <SnackBar snack={snack} message={message} /> */}
      <div style={{display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      border: '1px gray dashed', 
      padding: '30px 40px', 
      margin: '20px 10px',
      backgroundColor: '#e8f2ff',
      borderRadius: '7px'
      }}>
        <Button
        variant="contained"
        component="label"
        startIcon={<Iconify icon="eva:plus-fill" />}
            >
          Upload CSV
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
                onChange={(e) => {
                  const files = e.target.files;
                  // console.log(files);
                  if (files) {
                    // console.log(files[0]);
                    Papa.parse(files[0], {
                      header:true,
                      // eslint-disable-next-line
                      complete: function(results) {
                      setCsvData(results.data)
                      }},
                    )
                  }
                }}
            hidden
          />
        </Button>

        {csvData.length > 1 && (
          <Button
          onClick={handleSubmit}
        style={{marginLeft: '30px', backgroundColor: 'green'}}
        variant="contained"
        component="label"
        startIcon={<Iconify icon="eva:cloud-upload-outline" />}
        
        >
          Submit Record
        </Button>
        )}
      </div>


    {
      csvData.length > 1 && (
        <div>
          {snack && (
                <p style={{color: 'red', textAlign: 'center'}}>
                {message}
                </p>
              )}
          <Box
          style={{display: isMobile ? 'block' : 'flex', alignItems: 'center', justifyContent: 'center'}}
            component="form"
              sx={{
                '& > :not(style)': { m: 1, width: isMobile ? '95%': '100%', padding: isMobile ? '0px 0px' : '20px 0px' },
              }}
              noValidate
              autoComplete="off"
            >
            
            <div>
           
              <TextField 
                id="outlined-basic" 
                label="Title of Datasheet" 
                variant="outlined" 
                fullWidth
                onChange={(e) => setFormData({...formData, title: e.target.value})}
              />
              </div>

              <Autocomplete
                disablePortal
                clearIcon={null}
                id="combo-box-demo"
                value={species}
                options={headers}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Column for Species" />}
                onChange={(event, value) => setSpecies(value.value)}
                fullWidth
              />

              <Autocomplete
                disablePortal
                clearIcon={null}
                id="combo-box-demo"
                value={number}
                options={headers}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Column for Count" />}
                onChange={(event, value) => setNumber(value.value)}
                fullWidth
              />

            <Autocomplete
                disablePortal
                clearIcon={null}
                id="combo-box-demo"
                value={formData.protocol}
                options={protocols}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select Protocol" />}
                onChange={(event, value) => setFormData({...formData, protocol: value.value})}
                fullWidth
                
              />

        </Box>
        <ReactFlexyTable data={csvData} pageSize={50} />
        </div>
      )
    }

    </Page>
  );
}