/* eslint-disable */

import PropTypes from 'prop-types';
import moment from 'moment'

// material

import { styled } from '@mui/material/styles';
import { CSVLink } from "react-csv";
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';

// component

import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

CheckListToolBar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  data: PropTypes.array
};

export default function CheckListToolBar({ numSelected, filterName, onFilterName, data }) {

  // Generate CSV file from array of records
   
const exportSpecies = data.map((bird) => {
  return {
    observer: bird.observer,
    title: bird.title,
    ['Species Name']: bird.englishName,
    Date: moment(bird.createdAt).format('L'),
    Time: moment(bird.createdAt).format('LT'),
    ['Seen/Heard']: bird.seenOrHeard,
    Latitude: bird.latitude,
    Longitude: bird.longitude,
    pentad: bird.pentad,
    Count: bird.count,
    ['Count is']: bird.countIs,
    Habitat: "Unspecified",
    // Fieldsheet: `${bird.pentad}_${moment(bird.createdAt).format('MM-DD-YYYY')}`,
    ['ISO Date']: bird.createdAt

  }
})

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search observer..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <CSVLink
        data={exportSpecies}
        filename={"checklist.csv"}
        className="btn btn-primary"
        target="_blank"
        style={{border: 'solid 1px gray', padding: '7px', borderRadius: '20px'}}
      >
        <Tooltip title="Download">
          <IconButton>
            <Iconify icon="eva:download-fill" />
          </IconButton>
        </Tooltip>
        </CSVLink>

      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
