import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from '@mui/material';


export default function RecipeReviewCard() {


  return (
    <Card sx={{ maxWidth: '100%', paddingBottom: '40px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
          <AttachFileIcon />
          </Avatar>
        }
        action={
            <Button variant='contained' component="label" endIcon={<DownloadIcon />} >
            <a  style={{textDecoration: 'none', color: '#fff'}} rel="noreferrer" href="https://ik.imagekit.io/1ak7e44tjrf1/birdplus/userguide_DSyw1y_7I.pdf?updatedAt=1680792524616" download>
                Download
            </a>
            </Button>
        }
        title="BirdPlus userguide"
        subheader="A detailed documentation of how to use BirdPlus"
      />
 
      
    </Card>
  );
}