import { START_LOADING, END_LOADING, FETCH_ALL, FETCH_BIRD, FETCH_BIRDS_BY_USER } from '../../constants/types';
import * as api from '../../api';

export const getBird = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchbird(id);

    dispatch({ type: FETCH_BIRD, payload: { post: data } });
  } catch (error) {
    console.log(error);
  }
};

export const getBirds = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchBirds(page);

    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getBirdsByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, summary } } = await api.fetchBirdsByUser(searchQuery);

    console.log(summary)

    dispatch({ type: FETCH_BIRDS_BY_USER, payload: { data, summary } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

